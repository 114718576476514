import { navigate } from '@mr-yum/frontend-core/dist/services/routes'
import { DrawerContainer, useBreakpoint } from '@mr-yum/frontend-ui'
import React, { useCallback, useState } from 'react'

import { GuestProfileMenu } from '@/components/GuestProfile/GuestProfileMenu'
import { RewardMembershipCTA } from '@/components/LoyaltyMembership/LoyaltyBanners'
import { VenueMembershipPayload } from '@/gql/graphql'
import { useLogger } from '@/hooks/useLogger'
import { routes } from '@/lib/routes'

export const ManageMembershipCTA = ({
  venueId,
  venueName,
  venueSlug,
  program,
}: {
  venueId: string
  venueName: string
  venueSlug: string
  program: Pick<
    VenueMembershipPayload,
    'id' | 'name' | 'logo' | 'configuration' | 'membership'
  >
}) => {
  const [open, setGuestProfileOpen] = useState(false)
  const { logEvent } = useLogger()
  const breakpoint = useBreakpoint()
  const isMobile = ['sm', 'md'].includes(breakpoint)

  const handleClose = useCallback(() => {
    logEvent('Closed guest profile menu')
    setGuestProfileOpen(false)
  }, [logEvent])

  const handleGuestProfileOpen = useCallback(async () => {
    logEvent('Opened guest profile menu')

    if (isMobile) {
      await navigate(routes.guestProfile, {
        venueSlug,
      })
    } else {
      setGuestProfileOpen(true)
    }
  }, [isMobile, logEvent, venueSlug])

  return (
    <>
      <RewardMembershipCTA
        venueName={venueName}
        program={program}
        cta="manageLoyaltyAccount"
        onClick={handleGuestProfileOpen}
        defaultDescription="Earn rewards with a membership. Check your profile"
      />
      <DrawerContainer isOpen={open} onClose={handleClose} anchor="right">
        <GuestProfileMenu
          venueSlug={venueSlug}
          venueId={venueId}
          handleClose={handleClose}
          venueName={venueName}
        />
      </DrawerContainer>
    </>
  )
}
