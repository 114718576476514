import React from 'react'

import { Text } from '@/components/Skeleton/Text'

export const GuestProfileLoader = () => {
  return (
    <div data-testid="guest-profile-loader">
      <Text chars={10} className="mb-10 my-heading-lg" />

      <Text chars={22} className="mb-10 my-body-md" />

      <Text chars={24} className="mb-10 my-body-md" />

      <Text chars={16} className="mb-4 my-body-md" />
    </div>
  )
}
