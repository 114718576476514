import React from 'react'

import {
  CurrentUserQuery,
  GuestMembershipRewards,
  VenueMembershipPayload,
} from '@/gql/graphql'

import { RewardSummaryInfoBanner } from '../LoyaltyMembership/LoyaltyBanners'
import { ActivateMembershipCTA } from './ActivateMembershipCTA'
import { ManageMembershipCTA } from './ManageMembershipCTA'
import { SignUpForMembershipCTA } from './SignUpForMembershipCTA'

export const ExternalLoyaltyMembershipCTA = ({
  currentUser,
  venueId,
  venueName,
  venueSlug,
  programs,
  showManageMembershipCTA = true,
  rewardsData,
}: {
  currentUser: CurrentUserQuery['currentUser']
  venueId: string
  venueName: string
  venueSlug: string
  programs: Array<
    Pick<
      VenueMembershipPayload,
      'id' | 'name' | 'logo' | 'configuration' | 'membership'
    >
  >
  showManageMembershipCTA?: boolean
  rewardsData?: GuestMembershipRewards | null | undefined
}) => {
  return (
    <>
      {programs.map((program) => {
        const isLinked = Boolean(program.membership.id)

        if (currentUser && !isLinked) {
          return (
            <ActivateMembershipCTA
              key={program.id}
              venueName={venueName}
              venueSlug={venueSlug}
              program={program}
            />
          )
        }

        if (currentUser && isLinked && rewardsData) {
          return (
            <RewardSummaryInfoBanner
              key={program.id}
              program={program}
              points={rewardsData.pointsBalance}
              rewards={rewardsData.rewards.length}
            />
          )
        }

        if (currentUser && isLinked && showManageMembershipCTA) {
          return (
            <ManageMembershipCTA
              key={program.id}
              venueId={venueId}
              venueName={venueName}
              venueSlug={venueSlug}
              program={program}
            />
          )
        }

        if (!currentUser) {
          return (
            <SignUpForMembershipCTA
              key={program.id}
              venueName={venueName}
              venueSlug={venueSlug}
              program={program}
            />
          )
        }

        return null
      })}
    </>
  )
}
