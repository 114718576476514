import React from 'react'

import { RewardMembershipCTA } from '@/components/LoyaltyMembership/LoyaltyBanners'
import { VenueMembershipPayload } from '@/gql/graphql'
import { routes } from '@/lib/routes'

export const SignUpForMembershipCTA = ({
  venueName,
  venueSlug,
  program,
}: {
  venueName: string
  venueSlug: string
  program: Pick<
    VenueMembershipPayload,
    'id' | 'name' | 'logo' | 'configuration' | 'membership'
  >
}) => {
  return (
    <RewardMembershipCTA
      venueName={venueName}
      program={program}
      cta="signUp"
      href={routes.rewardLinkMembership.toPath!({
        venueSlug,
        programId: program.id,
      })}
      defaultDescription="Earn rewards with a membership. Sign Up"
    />
  )
}
